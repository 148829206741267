// interfaces
import {
  PriceRuleGraphRowData,
  CourtSportData,
  PriceRuleTableFields
} from '@interfaces/priceRules/priceRules';

//components
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import PriceRulePagination from '@components/PriceRulePagination/PriceRulePagination';
// hooks
import { useTranslation } from 'react-i18next';

type PriceRuleGraphProps = {
  graphRows: PriceRuleGraphRowData[];
  courtSportArr: CourtSportData[];
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
  currentItem: CourtSportData;
  isLoading: boolean;
};
const PriceRuleGraph = ({
  graphRows,
  courtSportArr,
  handlePageChange,
  currentPage,
  currentItem,
  isLoading
}: PriceRuleGraphProps) => {
  const { t } = useTranslation();
  return (
    <Card
      className="priceRuleGraphWrapper"
      sx={{
        px: 1,
        width: '100%'
      }}
    >
      <CardHeader
        title={t('stepFour.price_rule_graph_title')}
        subheader={t('stepFour.price_rule_graph_subtitle')}
        sx={{ flexWrap: 'wrap', gap: 2 }}
        action={
          <PriceRulePagination
            courtSportArr={courtSportArr}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            currentItem={currentItem}
          />
        }
      />
      {!isLoading ? (
        <CardContent sx={{ position: 'relative' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: '#f5f5f5', th: { textAlign: 'center' } }}>
                <TableCell id="hours" sx={{ width: { xs: '15%', sm: '8%' } }} />
                <TableCell sx={{ width: { xs: '30%' } }}>{t('workweek')}</TableCell>
                <TableCell sx={{ width: { xs: '30%' } }}>{t('sat')}</TableCell>
                <TableCell sx={{ width: { xs: '30%' } }}>{t('sun')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {graphRows.length > 0 &&
                graphRows.map((graphRow, i) => (
                  <TableRow key={i} sx={{ height: '40px' }}>
                    {Object.values(graphRow).length > 0 &&
                      Object.values(graphRow).map((value, i) =>
                        typeof value === 'string' ? (
                          <TableCell key={i}>{value}</TableCell>
                        ) : value && value.rowSpan && value.text ? (
                          <Tooltip
                            key={i}
                            arrow
                            title={value.ruleName && value.ruleName}
                            placement="right"
                          >
                            <TableCell
                              sx={{
                                backgroundColor: `${value.color && value.color}`,
                                textAlign: 'left'
                              }}
                              rowSpan={value.rowSpan && value.rowSpan}
                            >
                              {value.text &&
                                value.text.length > 0 &&
                                value.text.map(textEl => (
                                  <Typography key={textEl} variant="subtitle1" textAlign="center">
                                    {textEl}
                                  </Typography>
                                ))}
                            </TableCell>
                          </Tooltip>
                        ) : (
                          value &&
                          value.color && (
                            <TableCell
                              key={i}
                              sx={{
                                backgroundColor: `${value.color && value.color}`
                              }}
                            ></TableCell>
                          )
                        )
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </Card>
  );
};

export default PriceRuleGraph;
