//react
import { FC } from 'react';
//hooks
import useCalendarsLogic from '@hooks/calendars/useCalendarsLogic';
import useWindowSize from '@hooks/windowSize/useWindowSize';
import {
  useSportCenterAppointments,
  useSportCenterAppointmentsForCoach
} from '@api/queries/appointments/appointments';
import { useTranslation } from 'react-i18next';
//layouts
import { CalendarLayout } from '@layout/CalendarLayout/CalendarLayout';
//containers
import CalendarHeaderActions from '@containers/CalendarHeaderAction/CalendarHeaderAction';
import AddApointment from '@containers/Appointment/AddAppointment/AddAppointment';
import MobileMenu from '@containers/MobileMenu/MobileMenu';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import { EventFormattedType } from '@interfaces/appointments/appointments';
//mui
import { Avatar, Box, Button, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
//styles
import '@styles/pages/adminCalendar.scss';
//fullcalendar
import { EventClickArg, EventContentArg } from '@fullcalendar/core';
//helpers
import {
  checkPermissionFunc,
  formatCalendarEvents,
  formatCalendarEventsForCoach,
  getAppointmentColor,
  getSlotTimeBasedOnSelectedDay
} from '@helpers/utility';
//theme
import theme from '@src/theme';
//dayjs
import dayjs from 'dayjs';
//recoil
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';
import { calendarFullScreenAtom } from '@atoms/calendarFullScreen';
//enums
import { RoleEnum } from '@enum/roleEnum';
import { PermissionsEnum } from '@enum/permissionsEnum';
//icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import AddIcon from '@mui/icons-material/Add';

const AdminCalendarPage: FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const resetAppointmentForm = useResetRecoilState(appointmentFormAtom);
  const lng = localStorage.getItem('lng');
  const setPopup = useSetRecoilState(popupAtom);
  const [calendarFullScreen, setCalendarFullScreen] = useRecoilState(calendarFullScreenAtom);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    sportCenterID,
    calendarRef,
    selectRows,
    onAddAppointment,
    openAddAppointment,
    setOpenAddAppointment,
    addAppointmentType,
    setAddAppointmentType,
    addRegularAppointment,
    addPermanentAppointment,
    selectedTimeDate,
    setSelectedTimeDate,
    datesChanged,
    calendarWorkingHours,
    appointmentID,
    setAppointmentID,
    selectedCourtCalendar,
    selectedSportCalendar,
    fromDateCalendar,
    toDateCalendar,
    calendarTitle,
    handleChangeDay,
    selectedDate,
    setSelectedDate,
    sportCenterCourts,
    prevCourts,
    nextCourts,
    visibleSportCenterCourts,
    openCalendarMobileMenu,
    setOpenCalendarMobileMenu,
    selectedCourt,
    setSelectedCourt,
    allowedCourtsForCoach,
    courtsPriceRules
  } = useCalendarsLogic();
  const calendarFromHour = getSlotTimeBasedOnSelectedDay(
    fromDateCalendar,
    calendarWorkingHours
  ).from;
  const calendarToHour = getSlotTimeBasedOnSelectedDay(fromDateCalendar, calendarWorkingHours).to;

  function onAppointmentError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { data: appointments, refetch: refetchAppointments } =
    currentCMS?.role === RoleEnum.COACH
      ? useSportCenterAppointmentsForCoach(
          onAppointmentError,
          sportCenterID,
          fromDateCalendar,
          toDateCalendar,
          selectedCourtCalendar ? selectedCourtCalendar : '',
          selectedSportCalendar ? selectedSportCalendar : ''
        )
      : useSportCenterAppointments(
          onAppointmentError,
          sportCenterID,
          fromDateCalendar,
          toDateCalendar,
          selectedCourtCalendar ? selectedCourtCalendar : '',
          selectedSportCalendar ? selectedSportCalendar : ''
        );

  const appointmentsWithCourtSport = appointments?.data.data.filter(
    appointment => appointment.court_sport !== null
  );
  const { width } = useWindowSize();

  let eventsFormatted: EventFormattedType[] = [];
  if (currentCMS?.role === RoleEnum.COACH) {
    formatCalendarEventsForCoach(eventsFormatted, appointmentsWithCourtSport, lng);
  } else {
    formatCalendarEvents(eventsFormatted, appointmentsWithCourtSport, lng);
  }

  const renderEventContent = (eventInfo: EventContentArg) => {
    let diffStartEndTimeInMinutes = dayjs(eventInfo.event.endStr).diff(
      dayjs(eventInfo.event.startStr),
      'm'
    );
    let datetime_start = dayjs(eventInfo.event.startStr);
    let courtSportName = `${eventInfo.event.extendedProps.courtName} - ${eventInfo.event.extendedProps.sportName}`;
    let appointmentTimeColor = getAppointmentColor(
      eventInfo.event.extendedProps.reservedFrom,
      eventInfo.event.extendedProps.status,
      datetime_start,
      eventInfo.event.extendedProps.recurringAppointmentID
    );
    return (
      <>
        {eventInfo.event.extendedProps.status ? (
          <Tooltip
            title={`${t('appointmentHolder')}: ${eventInfo.event.extendedProps.userName}, ${t(
              'courtSport'
            )}: ${courtSportName}, ${t(
              'calendar.regularAppointment.appointment_duration_time'
            )}: ${diffStartEndTimeInMinutes}`}
            placement="top"
          >
            <Box>
              <Box ml={1} mt={0.3} display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Box
                    sx={{
                      background:
                        currentCMS?.role === RoleEnum.ADMIN
                          ? appointmentTimeColor.timeColor
                          : '#ff8c00',
                      width: '35px',
                      height: '19px',
                      borderRadius: '5px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant={'body4'}
                      color={'white'}
                      fontFamily={'Inter-bold'}
                      lineHeight={'20px'}
                    >
                      {eventInfo.event.extendedProps.start}
                    </Typography>
                  </Box>
                  <Avatar
                    alt="user-image"
                    src={eventInfo.event.extendedProps.profilePhoto?.src}
                    sx={{ borderRadius: '5px', width: '20px', height: '19px', ml: 0.5 }}
                  />
                </Box>
                {eventInfo.event.extendedProps.userName ? (
                  <Typography
                    color={theme.palette.appointmentTextColor.main}
                    fontFamily={'Inter-bold'}
                    variant={'body3'}
                    lineHeight={'16px'}
                    mt={0.8}
                  >
                    {eventInfo.event.extendedProps.userName}
                  </Typography>
                ) : null}
                <Typography
                  color={theme.palette.appointmentTextColor.main}
                  variant={'body3'}
                  mt={0.4}
                >
                  {courtSportName}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        ) : currentCMS?.role === RoleEnum.COACH && !eventInfo.event.extendedProps.status ? (
          <Box
            sx={{
              background: '#21246E',
              width: '35px',
              height: '20px',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center'
            }}
            ml={1}
            mt={0.3}
          >
            <Typography
              variant={'body4'}
              color={'white'}
              fontFamily={'Inter-bold'}
              lineHeight={'20px'}
            >
              {eventInfo.event.extendedProps.start}
            </Typography>
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <Grid container sx={{ alignItems: 'start' }} p={2}>
      <Grid container>
        <Grid item xs={12} p={2} mb={1}>
          <CalendarHeaderActions
            addApointment={checkPermissionFunc(
              currentCMS,
              PermissionsEnum.create_appointments,
              onAddAppointment
            )}
            calendarTitle={calendarTitle}
            handleChangeDay={handleChangeDay}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            calendarRef={calendarRef}
            setOpenCalendarMobileMenu={setOpenCalendarMobileMenu}
          />
          <Button
            sx={{
              color: 'gray',
              marginBottom: -3,
              marginTop: 1,
              display: { xs: 'none', sm: 'flex' }
            }}
            onClick={() =>
              calendarFullScreen ? setCalendarFullScreen(false) : setCalendarFullScreen(true)
            }
          >
            {calendarFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </Button>
        </Grid>
        <Grid item xs={12} p={2}>
          {sportCenterCourts &&
          eventsFormatted &&
          calendarWorkingHours &&
          calendarFromHour &&
          calendarToHour ? (
            <Box>
              <Box
                display={
                  sportCenterCourts.length > visibleSportCenterCourts.length ? 'flex' : 'none'
                }
                flexDirection={'row'}
                justifyContent={'space-between'}
                mb={-6}
                ml={-1}
              >
                <Button
                  sx={{
                    height: '42px',
                    width: '42px',
                    zIndex: 2
                  }}
                  onClick={prevCourts}
                >
                  <KeyboardArrowLeftIcon sx={{ color: 'gray' }} />
                </Button>
                <Button
                  sx={{
                    height: '42px',
                    width: '42px',
                    zIndex: 2,
                    marginRight: -1
                  }}
                  onClick={nextCourts}
                >
                  <KeyboardArrowRightIcon sx={{ color: 'gray' }} />
                </Button>
              </Box>
              <CalendarLayout
                resources={visibleSportCenterCourts}
                resourceLabelContent={(resource: any) => {
                  if (resource.resource.title.length > 14 && width < 1600 && width > 500) {
                    return `${resource.resource.title.substring(0, 14)}..`;
                  } else if (resource.resource.title.length > 8 && width <= 500) {
                    return `${resource.resource.title.substring(0, 8)}..`;
                  } else {
                    return resource.resource.title;
                  }
                }}
                slotEventOverlap={false}
                eventClick={(eventinfo: EventClickArg) => {
                  if (
                    eventinfo.event.extendedProps.courtName &&
                    eventinfo.event.extendedProps.status
                  ) {
                    setAppointmentID(eventinfo.event.extendedProps.id);
                    onAddAppointment();
                  }
                }}
                eventContent={renderEventContent}
                datesChanged={datesChanged}
                events={eventsFormatted}
                calendarRef={calendarRef}
                initialView={'resourceTimeGridDay'}
                nowIndicator={true}
                weekends={true}
                titleFormat={'MMM D'}
                dayMaxEvents={true}
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short'
                }}
                dayHeaderFormat={'ddd D'}
                allDaySlot={false}
                slotMinTime={calendarFromHour}
                slotMaxTime={calendarToHour}
                selectable={true}
                selectRows={selectRows}
                selectAllow={function (selectInfo) {
                  let startTime = dayjs(selectInfo.startStr);
                  let endTime = dayjs(selectInfo.endStr);
                  let minutesDifference = endTime.diff(startTime, 'minutes');
                  let foundPriceRule = courtsPriceRules.find(
                    (obj: { id: number; minutes: number[] }) =>
                      obj.id.toString() === selectInfo.resource?.id
                  );
                  if (
                    (dayjs().isBefore(startTime) || dayjs().isSame(startTime)) &&
                    currentCMS?.role === RoleEnum.COACH &&
                    selectInfo.resource
                  ) {
                    return allowedCourtsForCoach.includes(+selectInfo.resource.id);
                  } else if (
                    (dayjs().isBefore(startTime) || dayjs().isSame(startTime)) &&
                    currentCMS?.role !== RoleEnum.COACH &&
                    foundPriceRule &&
                    selectInfo.resource
                  ) {
                    return foundPriceRule.minutes.includes(minutesDifference);
                  } else {
                    return false;
                  }
                }}
                eventMaxStack={2}
                moreLinkClick={'popover'}
                dayPopoverFormat={'MMMM D'}
                contentHeight={isMobile || calendarFullScreen ? '80vh' : '67vh'}
              />
              <Box
                display={'flex'}
                width={{ xs: '100%', md: '70%' }}
                justifyContent={'end'}
                zIndex={2}
                position={'fixed'}
                bottom={'17.5vh'}
              >
                <Button
                  sx={{
                    background: '#6366F1',
                    color: 'white',
                    width: '56px',
                    height: '56px',
                    minWidth: 0,
                    borderRadius: '16px',
                    marginRight: 14,
                    zIndex: 2,
                    '&:hover': { backgroundColor: '#6366F1' },
                    display: { xs: 'flex', lg: 'none' },
                    padding: '8px'
                  }}
                  onClick={checkPermissionFunc(
                    currentCMS,
                    PermissionsEnum.create_appointments,
                    onAddAppointment
                  )}
                >
                  <AddIcon sx={{ width: '24px', height: '24px' }} />
                </Button>
              </Box>
            </Box>
          ) : (
            <Loading />
          )}
        </Grid>
      </Grid>
      <AddApointment
        anchor="right"
        open={openAddAppointment}
        onClose={() => {
          setOpenAddAppointment(false);
          setSelectedTimeDate({
            dateAppointment: null,
            startAppointment: '',
            durationAppointment: ''
          });
          setAppointmentID(undefined);
          setAddAppointmentType('regularAppointment');
          resetAppointmentForm();
          refetchAppointments();
          setSelectedCourt('');
        }}
        addAppointmentType={addAppointmentType}
        addRegularAppointment={addRegularAppointment}
        addPermanentAppointment={addPermanentAppointment}
        dateAppointment={selectedTimeDate.dateAppointment}
        startAppointment={selectedTimeDate.startAppointment}
        durationAppointment={selectedTimeDate.durationAppointment}
        appointmentID={appointmentID}
        sportCenterID={sportCenterID}
        calendarWorkingHours={calendarWorkingHours}
        selectedCourt={selectedCourt}
      />
      <MobileMenu
        anchor="right"
        open={openCalendarMobileMenu}
        onClose={() => setOpenCalendarMobileMenu(false)}
      />
    </Grid>
  );
};

export default AdminCalendarPage;
