//services
import {
  getAppointments,
  getAppointmentsByID,
  getAppointmentsByUser,
  getRecurringAppointments,
  getRecurringAppointmentsByID,
  getSportCenterAppointmentsForCoach
} from '@services/appointments/appointments';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';
//services
import { getSportCenterAppointments } from '@services/appointments/appointments';
//interfaces
import {
  RecurringAppointmentResponseType,
  WholeAppointmentsResponse,
  ReccuringAppointmentsResponseType,
  AllAppointmentsRequestType,
  AllAppointmentsResponseType
} from '@interfaces/appointments/appointments';
import {
  AppointmentsByUserParamsRequest,
  AppointmentsByUserResponse,
  RegularAppointmentResponseType
} from '@interfaces/appointments/appointments';

export const useSportCenterAppointments = (
  onError: (err: Error) => void,
  sportCenter: number | undefined,
  from_date: string,
  to_date: string,
  court_id?: number | string,
  sport_id?: number | string,
  status?: string,
  user_id?: number
): UseQueryResult<WholeAppointmentsResponse, Error> =>
  useQuery(
    [
      'sportCenterAppointments',
      sportCenter,
      from_date,
      to_date,
      court_id,
      sport_id,
      status,
      user_id
    ],
    () =>
      getSportCenterAppointments(
        sportCenter,
        from_date,
        to_date,
        court_id,
        sport_id,
        status,
        user_id
      ),
    {
      onError: err => onError(err),
      enabled: !!from_date && !!to_date
    }
  );

export const useAppontmentsByUser = (
  req: AppointmentsByUserParamsRequest,
  onError: (err: Error) => void,
  onSuccess?: (data: AppointmentsByUserResponse[]) => void
): UseQueryResult<{ data: { data: AppointmentsByUserResponse[] } }, Error> =>
  useQuery(
    ['appointemnts-by-user', req.userId],
    () => {
      if (req.userId) return getAppointmentsByUser(req.userId.toString());
      return null;
    },
    {
      onError: err => onError(err),
      onSuccess: res => onSuccess && onSuccess(res.data.data)
    }
  );

export const useAppointmentByID = (
  id: number | undefined,
  onSuccess: (data: RegularAppointmentResponseType) => void,
  onError: (err: Error) => void
): UseQueryResult<RegularAppointmentResponseType, Error> =>
  useQuery(['appointment', id], () => getAppointmentsByID(id), {
    onSuccess: res => onSuccess(res),
    onError: err => onError(err),
    enabled: !!id
  });

export const useRecurringAppointmentByID = (
  id: number | undefined,
  onSuccess: (data: RecurringAppointmentResponseType) => void,
  onError: (err: Error) => void
): UseQueryResult<RecurringAppointmentResponseType, Error> =>
  useQuery(['recurringAppointment', id], () => getRecurringAppointmentsByID(id), {
    onSuccess: res => onSuccess(res),
    onError: err => onError(err),
    enabled: !!id
  });

export const useRecurringAppointments = (
  onError: (err: Error) => void,
  sportCenterId: number | undefined,
  page: number,
  per_page: number,
  search?: string
): UseQueryResult<ReccuringAppointmentsResponseType, Error> =>
  useQuery(
    ['recurringAppointments', sportCenterId, page, per_page, search],
    () => getRecurringAppointments(sportCenterId, page, per_page, search),
    {
      onError: err => onError(err),
      enabled: !!sportCenterId
    }
  );

export const useSportCenterAppointmentsForCoach = (
  onError: (err: Error) => void,
  sportCenter: number | undefined,
  from_date: string,
  to_date: string,
  court_id?: number | string,
  sport_id?: number | string,
  status?: string,
  user_id?: number
): UseQueryResult<WholeAppointmentsResponse, Error> =>
  useQuery(
    [
      'sportCenterAppointmentsForCoach',
      sportCenter,
      from_date,
      to_date,
      court_id,
      sport_id,
      status,
      user_id
    ],
    () =>
      getSportCenterAppointmentsForCoach(
        sportCenter,
        from_date,
        to_date,
        court_id,
        sport_id,
        status,
        user_id
      ),
    {
      onError: err => onError(err),
      enabled: !!from_date && !!to_date
    }
  );

export const useAppointments = (
  params?: AllAppointmentsRequestType,
  onError?: (err: Error) => void
): UseQueryResult<AllAppointmentsResponseType, Error> =>
  useQuery(['appointments', params], () => getAppointments(params), {
    onSuccess: data => data.data,
    select: data => data.data,
    onError: err => onError && onError(err)
  });
