// react
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useMemo } from 'react';
// components
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  CircularProgress,
  Avatar
} from '@mui/material';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
// interfaces
import { workingDaysArrType } from '@interfaces/SportCenters/SportCenter';
import { CourtSportData, TimePricesType } from '@interfaces/priceRules/priceRules';
import { RecurringAppointmentCreateRequest } from '@interfaces/appointments/appointments';
// helpers
import { getAvailableTimesForTimePicker, handleChecked } from '@src/helpers/utility';
// icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { PlusCircle } from '@components/icons/icons';
//hooks
import { useSportCenterInfinityMembershipsUsers } from '@api/queries/user/user';
import useLastElementScroll from '@hooks/lastElementScroll/useLastElementScroll';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// dayjs
import dayjs from 'dayjs';
// atoms
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';
// enums
import { RoleEnum } from '@enum/roleEnum';
//mui-locale
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/sr';
import 'dayjs/locale/en';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type PermanentAppointmentProps = {
  onClose: () => void;
  courtItems?: CourtSportData[];
  reccuringAppointmentId?: number;
  editAppointment: boolean;
  sportCenterID: number | undefined;
  controlRecurringAppointment: Control<RecurringAppointmentCreateRequest, any>;
  handleRecurringAppointmentSubmit: UseFormHandleSubmit<RecurringAppointmentCreateRequest>;
  onRecurringAppointmentSubmit: (data: RecurringAppointmentCreateRequest) => void;
  getRecurringAppointmentValues: UseFormGetValues<RecurringAppointmentCreateRequest>;
  setRecurringAppointmentValue: UseFormSetValue<RecurringAppointmentCreateRequest>;
  isRecurringAppointmentSubmitted: boolean;
  courtSport: CourtSportData[];
  refUsersMenu: MutableRefObject<HTMLElement | null>;
  openUsersMenu: boolean;
  setOpenUsersMenu: Dispatch<SetStateAction<boolean>>;
  userInput: { id: number | string; name: string };
  setUserInput: Dispatch<SetStateAction<{ id: number | string; name: string }>>;
  handleSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  debounceValue: string;
  timePickerTimesRecurring: { minTime: number; maxTime: number };
  workingDays: string[];
  setWorkingDays: Dispatch<SetStateAction<string[]>>;
  durationTime: TimePricesType[];
  sportItems?: CourtSportData[];
  appointmentID?: number;
};

const AddPermanentAppointment: React.FC<PermanentAppointmentProps> = ({
  onClose,
  courtItems,
  reccuringAppointmentId,
  editAppointment,
  sportCenterID,
  controlRecurringAppointment,
  handleRecurringAppointmentSubmit,
  onRecurringAppointmentSubmit,
  getRecurringAppointmentValues,
  setRecurringAppointmentValue,
  isRecurringAppointmentSubmitted,
  courtSport,
  refUsersMenu,
  openUsersMenu,
  setOpenUsersMenu,
  userInput,
  setUserInput,
  handleSearch,
  debounceValue,
  timePickerTimesRecurring,
  workingDays,
  setWorkingDays,
  durationTime,
  sportItems,
  appointmentID
}): JSX.Element => {
  const workingDaysArr: workingDaysArrType[] = ['workweek', 'mon', 'tue', 'wed', 'thu', 'fri'];
  const weekendArr: workingDaysArrType[] = ['weekend', 'sat', 'sun'];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const setAppointmentForm = useSetRecoilState(appointmentFormAtom);
  const lng = localStorage.getItem('lng');

  const arrayOfAvailableTimes: string[] = getAvailableTimesForTimePicker(
    timePickerTimesRecurring.minTime,
    timePickerTimesRecurring.maxTime
  );

  const {
    data: sportCenterUsers,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching
  } = useSportCenterInfinityMembershipsUsers(
    sportCenterID,
    10,
    debounceValue,
    '',
    '',
    onInfinityUsersError
  );

  function onInfinityUsersError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { lastElementRef } = useLastElementScroll(
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  );

  const flattenedData = useMemo(
    () => (sportCenterUsers ? sportCenterUsers?.pages.flatMap(item => item.data.data) : []),
    [sportCenterUsers]
  );

  const menuItemsUsers = flattenedData.map((user, index) => (
    <Box ref={flattenedData.length === index + 1 ? lastElementRef : null} key={index}>
      <MenuItem
        value={user.user_id}
        onClick={() => {
          setUserInput({ id: user.user_id ? user.user_id : '', name: user.name });
          setOpenUsersMenu(false);
        }}
      >
        <Avatar
          sx={{
            height: '24px',
            width: '24px',
            fontSize: '0.75rem',
            marginRight: '2%'
          }}
        >
          {user.first_name?.charAt(0)}
          {user.last_name?.charAt(0)}
        </Avatar>
        <Typography fontSize={'1rem'} color={'#000000'}>
          {user.name}
        </Typography>
      </MenuItem>
    </Box>
  ));

  const menuItemsDurationTime = durationTime?.map((item, index) => (
    <MenuItem key={index} value={item.minutes}>
      {item.minutes}
    </MenuItem>
  ));

  if (courtSport.length < 1) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box
      component={'form'}
      onSubmit={handleRecurringAppointmentSubmit(onRecurringAppointmentSubmit)}
    >
      <Grid container spacing={2}>
        {/* Courts and sport */}
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={'bold'}>
            {t('calendar.permanentAppointment.fields_price_title')}
          </Typography>
          <Typography> {t('calendar.permanentAppointment.fields_price_subtitle')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="court_sport_id"
            rules={{ required: t('errorMessage').toString() }}
            control={controlRecurringAppointment}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl className="row justify-between wrap fullWidth">
                <InputLabel id="fields-sports-selectlabel" error={invalid}>
                  {`${t('field')} / ${t('sport')}  *`}
                </InputLabel>
                <Select
                  {...field}
                  labelId="fields-sports-selectlabel"
                  id="fields-sports-select"
                  label={`${t('field')} / ${t('sport')}  *`}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  error={invalid}
                  disabled={!!appointmentID && !editAppointment}
                >
                  {courtItems && courtItems.length > 0
                    ? courtItems.map(coSp => (
                        <MenuItem key={coSp.id} value={coSp.id}>
                          {coSp.name}
                        </MenuItem>
                      ))
                    : sportItems && sportItems.length > 0
                    ? sportItems.map(coSp => (
                        <MenuItem key={coSp.id} value={coSp.id}>
                          {coSp.name}
                        </MenuItem>
                      ))
                    : courtSport.map(coSp => (
                        <MenuItem key={coSp.id} value={coSp.id}>
                          {coSp.name}
                        </MenuItem>
                      ))}
                </Select>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        {/* price */}
        <Grid item xs={12}>
          <Controller
            name="price"
            rules={{ required: t('errorMessage').toString() }}
            control={controlRecurringAppointment}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl className="row justify-between wrap fullWidth">
                <TextField
                  {...field}
                  id="appointment.price"
                  label={t('calendar.permanentAppointment.appointment_price') + ' *'}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  error={invalid}
                  disabled={!!appointmentID && !editAppointment}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={'bold'}>
            {t('calendar.permanentAppointment.title')}
          </Typography>
          <Typography>{t('calendar.permanentAppointment.subtitle')}</Typography>
        </Grid>
        {/* Information about appointment  */}
        <Grid item xs={6}>
          <Controller
            name="name"
            rules={{ required: t('errorMessage').toString() }}
            control={controlRecurringAppointment}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl className="row justify-between wrap fullWidth">
                <TextField
                  {...field}
                  id="appointment.name"
                  label={t('calendar.permanentAppointment.name') + ' *'}
                  sx={{ width: '100%' }}
                  variant="outlined"
                  error={invalid}
                  disabled={!!appointmentID && !editAppointment}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        {/* Users */}
        <Grid item xs={6}>
          <Controller
            name="user_id"
            rules={{ required: t('errorMessage').toString() }}
            control={controlRecurringAppointment}
            render={({ fieldState: { invalid, error } }) => (
              <FormControl className="row justify-between wrap fullWidth" error={invalid}>
                <InputLabel id="bearer-name-selectlabel" htmlFor="outlined-bearer-name-search">
                  {t('calendar.permanentAppointment.bearer') + ' *'}
                </InputLabel>
                <OutlinedInput
                  onClick={() => setOpenUsersMenu(true)}
                  value={userInput.name}
                  onChange={handleSearch}
                  aria-describedby="base-name-helper-text"
                  inputProps={{
                    'aria-label': 'weight'
                  }}
                  label={t('calendar.permanentAppointment.bearer') + ' *'}
                  id="outlined-bearer-name-search"
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="search users" edge="end">
                        <SearchIcon color="disabled" />
                      </IconButton>
                    </InputAdornment>
                  }
                  disabled={
                    (!!appointmentID && !editAppointment) || currentCMS?.role === RoleEnum.COACH
                  }
                />
                <Box
                  ref={refUsersMenu}
                  sx={{ position: 'absolute', width: '100%', display: 'flex', zIndex: 999 }}
                  mt={7.5}
                >
                  {openUsersMenu ? (
                    <Paper
                      elevation={3}
                      sx={{
                        height: 150,
                        width: '100%',
                        overflowY: flattenedData.length > 3 ? 'scroll' : 'hidden'
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setAppointmentForm({
                            formType: 'permanentAppointment',
                            formObject: {
                              court_sport_id: getRecurringAppointmentValues('court_sport_id'),
                              name: getRecurringAppointmentValues('name'),
                              date_from: getRecurringAppointmentValues('date_from'),
                              date_to: getRecurringAppointmentValues('date_to'),
                              time_start: getRecurringAppointmentValues('time_start'),
                              minutes: getRecurringAppointmentValues('minutes'),
                              price: getRecurringAppointmentValues('price'),
                              mon: getRecurringAppointmentValues('mon'),
                              tue: getRecurringAppointmentValues('tue'),
                              wed: getRecurringAppointmentValues('wed'),
                              thu: getRecurringAppointmentValues('thu'),
                              fri: getRecurringAppointmentValues('fri'),
                              sat: getRecurringAppointmentValues('sat'),
                              sun: getRecurringAppointmentValues('sun'),
                              workweek: getRecurringAppointmentValues('workweek'),
                              weekend: getRecurringAppointmentValues('weekend')
                            }
                          });
                          navigate(`/users/${sportCenterID}/create-user`);
                        }}
                      >
                        <PlusCircle />
                        <Typography
                          ml={'2%'}
                          color={'primary.main'}
                          fontSize={'1rem'}
                          fontWeight={600}
                          borderBottom={'1px solid #6366F1'}
                        >
                          {t('superAdminUsers.addNewUser')}
                        </Typography>
                      </MenuItem>
                      {isLoading ? <CircularProgress /> : menuItemsUsers}
                      {isFetching && (
                        <MenuItem>{t('superAdminUsers.loadingUsers') + '...'}</MenuItem>
                      )}
                    </Paper>
                  ) : null}
                </Box>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="date_from"
            control={controlRecurringAppointment}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={lng === 'en' ? 'en' : 'sr'}
              >
                <DatePicker
                  label={t('from_day') + ' *'}
                  format="DD.MM.YYYY"
                  sx={{ width: '100%' }}
                  {...field}
                  disabled={!!appointmentID && !editAppointment}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="date_to"
            rules={{ required: t('errorMessage').toString() }}
            control={controlRecurringAppointment}
            render={({ field, fieldState: { error } }) => (
              <>
                <DatePicker
                  label={t('to_day') + ' *'}
                  format="DD.MM.YYYY"
                  sx={{ width: '100%' }}
                  disablePast
                  {...field}
                  disabled={!!appointmentID && !editAppointment}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
        {/* appointment start */}
        <Grid item xs={6}>
          <Controller
            name="time_start"
            control={controlRecurringAppointment}
            rules={{ required: t('errorMessage').toString() }}
            render={({ field, fieldState: { invalid, error } }) => (
              <>
                <CustomTimePicker
                  field={field}
                  id="regularAppointment.time_start"
                  labelId="regularAppointment.time_start-label"
                  label={t('calendar.regularAppointment.appointment_start') + '*'}
                  invalid={invalid}
                  disabled={!!appointmentID && !editAppointment}
                  availableTimes={arrayOfAvailableTimes}
                  helperText={error && error.message}
                  className="fullWidth"
                />
              </>
            )}
          />
        </Grid>
        {/* appointment duration */}
        <Grid item xs={6}>
          <Controller
            name="minutes"
            control={controlRecurringAppointment}
            rules={{ required: t('errorMessage').toString() }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl className="row justify-between wrap fullWidth">
                <InputLabel id="duration-time-selectlabel" error={invalid}>
                  {t('calendar.regularAppointment.appointment_duration_time') + ' *'}
                </InputLabel>
                <Select
                  {...field}
                  labelId="duration-time-selectlabel"
                  id="duration-time-select"
                  label={t('calendar.regularAppointment.appointment_duration_time') + ' *'}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  error={invalid}
                  disabled={
                    !getRecurringAppointmentValues('time_start') ||
                    durationTime.length < 1 ||
                    (!!appointmentID && !editAppointment) ||
                    dayjs().isAfter(
                      dayjs(
                        `${dayjs(getRecurringAppointmentValues('date_from'))?.format(
                          'YYYY-MM-DD'
                        )} ${getRecurringAppointmentValues('time_start')}`
                      )
                    )
                  }
                >
                  {menuItemsDurationTime}
                </Select>
                {error ? (
                  <FormHelperText error>{error.message}</FormHelperText>
                ) : getRecurringAppointmentValues('time_start') &&
                  !reccuringAppointmentId &&
                  !appointmentID &&
                  dayjs().isAfter(
                    dayjs(
                      `${dayjs(getRecurringAppointmentValues('date_from'))?.format(
                        'YYYY-MM-DD'
                      )} ${getRecurringAppointmentValues('time_start')}`
                    )
                  ) ? (
                  <FormHelperText error>{t('calendar.old_date_error_message')}</FormHelperText>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>
        {/* days */}
        <Grid container pt={5} spacing={2} px={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ svg: { verticalAlign: 'middle', marginLeft: '1%', marginBottom: '2px' } }}
            >
              {t('calendar.permanentAppointment.days_info')}
              <InfoOutlinedIcon fontSize="medium" />
            </Typography>
          </Grid>
          {isRecurringAppointmentSubmitted && !workingDays.length && (
            <Grid item sm={12} pl={2}>
              <FormHelperText error>{t('errorMessage')}</FormHelperText>
            </Grid>
          )}
          {/* Working days */}
          <Grid item xs={6} md={4} pl={2}>
            {workingDaysArr &&
              workingDaysArr.map(day => (
                <Controller
                  key={day}
                  name={day}
                  control={controlRecurringAppointment}
                  defaultValue={false}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormGroup>
                      <FormControlLabel
                        key={day}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            color: `${invalid ? 'red' : 'secondary.light'}`
                          }
                        }}
                        disabled={!!appointmentID && !editAppointment}
                        control={
                          <Checkbox
                            {...field}
                            onChange={e => {
                              handleChecked(e, workingDays, setWorkingDays);
                              if (e.target.value === t('workweek')) {
                                if (e.target.checked) {
                                  setRecurringAppointmentValue('mon', true);
                                  setRecurringAppointmentValue('tue', true);
                                  setRecurringAppointmentValue('wed', true);
                                  setRecurringAppointmentValue('thu', true);
                                  setRecurringAppointmentValue('fri', true);
                                  setRecurringAppointmentValue('workweek', true);
                                } else {
                                  setWorkingDays([]);
                                  setRecurringAppointmentValue('mon', false);
                                  setRecurringAppointmentValue('tue', false);
                                  setRecurringAppointmentValue('wed', false);
                                  setRecurringAppointmentValue('thu', false);
                                  setRecurringAppointmentValue('fri', false);
                                  setRecurringAppointmentValue('workweek', false);
                                }
                              }
                              field.onChange(e.target.checked);
                            }}
                            checked={
                              workingDays.includes(t(day)) ||
                              workingDays.includes(`${t('workweek')}`) ||
                              workingDays.includes(`${t('wholeweek')}`)
                            }
                            name={t(day).toString()}
                            value={t(day).toString()}
                          />
                        }
                        label={t(day).toString()}
                      />
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormGroup>
                  )}
                />
              ))}
          </Grid>
          {/* weekend */}
          <Grid item xs={6} md={3}>
            {weekendArr &&
              weekendArr.map(day => (
                <Controller
                  key={day}
                  name={day}
                  control={controlRecurringAppointment}
                  defaultValue={false}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            color: `${invalid ? 'red' : 'secondary.light'}`
                          }
                        }}
                        disabled={!!appointmentID && !editAppointment}
                        control={
                          <Checkbox
                            {...field}
                            onChange={e => {
                              handleChecked(e, workingDays, setWorkingDays);

                              if (e.target.value === t('weekend')) {
                                if (e.target.checked) {
                                  setRecurringAppointmentValue('sat', true);
                                  setRecurringAppointmentValue('sun', true);
                                  setRecurringAppointmentValue('weekend', true);
                                } else {
                                  setWorkingDays([]);
                                  setRecurringAppointmentValue('sat', false);
                                  setRecurringAppointmentValue('sun', false);
                                  setRecurringAppointmentValue('weekend', false);
                                }
                              }
                              field.onChange(e.target.checked);
                            }}
                            checked={
                              workingDays.includes(t(day).toString()) ||
                              workingDays.includes(`${t('weekend')}`) ||
                              workingDays.includes(`${t('wholeweek')}`)
                            }
                            name={t(day).toString()}
                            value={t(day).toString()}
                          />
                        }
                        label={t(day).toString()}
                      />
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormGroup>
                  )}
                />
              ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={2}
        >
          <Button sx={{ color: 'gray' }} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type="submit" variant="contained">
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddPermanentAppointment;
