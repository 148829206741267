// react
import { useMemo, useState } from 'react';

// dayjs
import dayjs from 'dayjs';

// enums
import { AppointmentStatusEnum } from '@enum/appointmentStatusEnum';

// interfaces
import { AppointmentsByUserResponse } from '@interfaces/appointments/appointments';
import { MRT_ColumnDef } from 'material-react-table';

// hooks
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

// queries
import { useAppontmentsByUser } from '@api/queries/appointments/appointments';
import { useSingleAdminUser } from '@api/queries/user/user';

// containers
import ProfileInfoCard from '@containers/ProfileInfoCard/ProfileInfoCard';

// components
import StatisticCard from '@components/StatisticCard/StatisticCard';
import { Grid, Box, Chip } from '@mui/material';
import Loading from '@components/Loading/Loading';
import DataCard from '@components/DataCard/DataCard';

// icons
import CheckIcon from '@mui/icons-material/Check';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// atoms
import { popupAtom } from '@atoms/popupAtom';

const AdminUserOverview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const userId: number = params.userId ? Number(params.userId) : 1;
  const sportCenterId: number = params.sportCenterId ? Number(params.sportCenterId) : 1;
  const [app_card, setCards] = useState({
    canceled: 0,
    completed: 0,
    scheduled: 0,
    in_progress: 0
  });
  const setPopup = useSetRecoilState(popupAtom);

  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { data: user, isLoading } = useSingleAdminUser(+userId, +sportCenterId, onError);
  const { data: appointemnts } = useAppontmentsByUser(
    {
      userId: user?.data.data.user_id ? user?.data.data.user_id : null,
      sport_center_id: sportCenterId
    },
    onError,
    (data: AppointmentsByUserResponse[]) => {
      const cards = {
        canceled: 0,
        completed: 0,
        scheduled: 0,
        in_progress: 0
      };
      data.forEach(app => {
        switch (app.status) {
          case AppointmentStatusEnum.canceled: {
            cards.canceled++;
            return;
          }
          case AppointmentStatusEnum.completed: {
            cards.completed++;
            return;
          }
          case AppointmentStatusEnum.scheduled: {
            cards.scheduled++;
            return;
          }
          case AppointmentStatusEnum.in_progress: {
            cards.in_progress++;
            return;
          }
        }
      });

      setCards(cards);
    }
  );

  const appointemntsColmns = useMemo<MRT_ColumnDef<AppointmentsByUserResponse>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('appointmentId')
      },
      {
        accessorKey: 'court_sport.court.name',
        header: t('field')
      },
      {
        accessorKey: 'court_sport.sport.name',
        header: t('sport')
      },
      {
        accessorKey: 'datetime_start',
        header: t('date'),
        Cell: ({ row }) => (
          <Box>
            <Box>{dayjs(row.original.datetime_start).format('DD.MM.YYYY.')}</Box>
            <Box>
              {dayjs(row.original.datetime_start).format('HH:mm') +
                ' - ' +
                dayjs(row.original.datetime_end).format('HH:mm')}
            </Box>
          </Box>
        )
      },
      {
        accessorKey: 'status',
        header: t('status'),
        Cell: ({ row }) => (
          <Chip
            label={t(row.original.status).toString()}
            variant="outlined"
            color={
              row.original.status === 'scheduled'
                ? 'warning'
                : row.original.status === 'in_progress'
                ? 'primary'
                : row.original.status === 'completed'
                ? 'success'
                : row.original.status === 'canceled' ||
                  row.original.status === 'pending' ||
                  row.original.status === 'unrealized'
                ? 'error'
                : 'default'
            }
          />
        )
      }
    ],
    []
  );

  if (isLoading) {
    return <Loading />;
  }
  if (!user) {
    return <Box>user does not exist</Box>;
  }

  return (
    <Box p={2}>
      <ProfileInfoCard
        name={user.data.data.first_name + ' ' + user.data.data.last_name}
        image={'data.data.data.profile_image'}
        lastSeen={
          appointemnts && appointemnts.data.data.length > 0
            ? `${t('last_appointment')}: ${dayjs(
                appointemnts.data.data[appointemnts.data.data.length - 1].datetime_start
              ).format('DD.MM.YYYY')}  `
            : ''
        }
        otherInfo={[
          {
            title: t('email'),
            value: user.data.data.email
          },
          {
            title: t('phone'),
            value: user.data.data.phone
          },
          {
            title: t('date_of_birth'),
            value: user.data.data.date_of_birth.toString()
          },
          {
            title: t('gender'),
            value: t(user.data.data.gender)
          }
        ]}
        isUser={true}
      />

      <Grid container my={2} spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticCard
            icon={<CheckIcon />}
            title={app_card.completed.toString()}
            subtitle={t('userProfile.completedTitle')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticCard
            icon={<ScheduleRoundedIcon />}
            title={app_card.scheduled.toString()}
            subtitle={t('userProfile.scheduledTitle')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticCard
            icon={<ClearRoundedIcon />}
            title={app_card.canceled.toString()}
            subtitle={t('userProfile.canceledTitle')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticCard
            icon={<InfoOutlinedIcon />}
            title={app_card.in_progress.toString()}
            subtitle={t('userProfile.in_progressTitle')}
          />
        </Grid>
      </Grid>

      <Box>
        <DataCard
          title={t('userProfile.data_card_title')}
          columns={appointemntsColmns}
          data={appointemnts ? appointemnts.data.data : []}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};

export default AdminUserOverview;
