//react
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useMemo } from 'react';
// hooks
import { useTranslation } from 'react-i18next';
import { useSportCenterInfinityMembershipsUsers } from '@api/queries/user/user';
import useLastElementScroll from '@hooks/lastElementScroll/useLastElementScroll';
import { useResetRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { UseMutateFunction } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// dayjs
import dayjs from 'dayjs';
// react hook form
import { Control, Controller, UseFormGetValues, UseFormHandleSubmit } from 'react-hook-form';
// components
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  Avatar,
  CircularProgress,
  Alert,
  AlertTitle
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
//icons
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PlusCircle } from '@components/icons/icons';
//interfaces
import { CourtSportData, TimePricesType } from '@interfaces/priceRules/priceRules';
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  RegularAppointmentCreateRequest,
  RegularAppointmentResponseType
} from '@interfaces/appointments/appointments';
//enums
import { AppointmentReservedFromEnum, AppointmentStatusEnum } from '@enum/appointmentStatusEnum';
import { RoleEnum } from '@enum/roleEnum';
//helpers
import { getAvailableTimesForTimePicker } from '@helpers/utility';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';
//theme
import theme from '@src/theme';
//mui-locale
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/sr';
import 'dayjs/locale/en';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type RegularAppointmentProps = {
  onClose: () => void;
  courtSportItem?: CourtSportData;
  appointmentID: number | undefined;
  courtItems?: CourtSportData[];
  editAppointment: boolean;
  sportCenterID: number | undefined;
  controlRegularAppointment: Control<RegularAppointmentCreateRequest, any>;
  handleRegularAppointmentSubmit: UseFormHandleSubmit<RegularAppointmentCreateRequest>;
  onRegularAppointmentSubmit: (data: RegularAppointmentCreateRequest) => void;
  courtSport: CourtSportData[];
  refUsersMenu: MutableRefObject<HTMLElement | null>;
  openUsersMenu: boolean;
  setOpenUsersMenu: Dispatch<SetStateAction<boolean>>;
  userInput: { id: number | string; name: string };
  setUserInput: Dispatch<SetStateAction<{ id: number | string; name: string }>>;
  handleSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  debounceValue: string;
  durationTime: TimePricesType[];
  getRegularAppointmentValues: UseFormGetValues<RegularAppointmentCreateRequest>;
  selectedAppointmentByID: RegularAppointmentResponseType | undefined;
  timePickerTimes: { minTime: number; maxTime: number };
  confirmAppointment: UseMutateFunction<SuccessResponse, Error, number, (data: string) => void>;
  declineAppointment: UseMutateFunction<SuccessResponse, Error, number, (data: string) => void>;
  sportItems?: CourtSportData[];
};
const AddRegularAppointment: React.FC<RegularAppointmentProps> = ({
  onClose,
  courtSportItem,
  appointmentID,
  courtItems,
  editAppointment,
  sportCenterID,
  controlRegularAppointment,
  handleRegularAppointmentSubmit,
  onRegularAppointmentSubmit,
  courtSport,
  refUsersMenu,
  openUsersMenu,
  setOpenUsersMenu,
  userInput,
  setUserInput,
  handleSearch,
  debounceValue,
  durationTime,
  getRegularAppointmentValues,
  selectedAppointmentByID,
  timePickerTimes,
  confirmAppointment,
  declineAppointment,
  sportItems
}) => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);
  const setAppointmentForm = useSetRecoilState(appointmentFormAtom);
  const lng = localStorage.getItem('lng');
  const appointmentDateTime = dayjs(
    `${getRegularAppointmentValues('regularAppointment_date')?.format(
      'YYYY-MM-DD'
    )} ${getRegularAppointmentValues('datetime_start')}`
  );
  const arrayOfAvailableTimes: string[] = getAvailableTimesForTimePicker(
    timePickerTimes.minTime,
    timePickerTimes.maxTime
  );

  const {
    data: sportCenterUsers,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching
  } = useSportCenterInfinityMembershipsUsers(
    sportCenterID,
    10,
    debounceValue,
    '',
    '',
    onInfinityUsersError
  );

  function onInfinityUsersError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }
  const courtSportID = getRegularAppointmentValues('court_sport_id');
  const date = getRegularAppointmentValues('regularAppointment_date');
  const timeStart = getRegularAppointmentValues('datetime_start');
  const navigate = useNavigate();

  const { lastElementRef } = useLastElementScroll(
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  );

  const flattenedData = useMemo(
    () => (sportCenterUsers ? sportCenterUsers?.pages.flatMap(item => item.data.data) : []),
    [sportCenterUsers]
  );

  const menuItemsUsers = flattenedData.map((user, index) => (
    <Box ref={flattenedData.length === index + 1 ? lastElementRef : null} key={index}>
      <MenuItem
        value={user.user_id}
        onClick={() => {
          setUserInput({ id: user.user_id ? user.user_id : '', name: user.name });
          setOpenUsersMenu(false);
        }}
      >
        <Avatar
          sx={{
            height: '24px',
            width: '24px',
            fontSize: '0.75rem',
            marginRight: '2%'
          }}
        >
          {user.first_name?.charAt(0)}
          {user.last_name?.charAt(0)}
        </Avatar>
        <Typography fontSize={'1rem'} color={'#000000'}>
          {user.name}
        </Typography>
      </MenuItem>
    </Box>
  ));

  const menuItemsDurationTime = durationTime?.map((item, index) => (
    <MenuItem key={index} value={item.minutes}>
      {item.minutes}
    </MenuItem>
  ));

  if (courtSport.length < 1) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box>
      {selectedAppointmentByID?.data.data.status === AppointmentStatusEnum.in_progress ||
      selectedAppointmentByID?.data.data.status === AppointmentStatusEnum.pending ? (
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Alert icon={<ErrorOutlineIcon sx={{ fontSize: 28 }} />} severity="warning">
              <AlertTitle>
                <Typography variant="h6" fontWeight={'bold'} color={'#ED6C02'}>
                  {t('calendar.confirmationAppointment.title')}
                </Typography>
              </AlertTitle>
              <Typography>{t('calendar.confirmationAppointment.firstPar')}</Typography>
              <Typography>{t('calendar.confirmationAppointment.secondPar')}</Typography>
              <Typography fontWeight={'bold'}>
                {t('calendar.confirmationAppointment.thirdPar')}
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12} mt={1.5} display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="contained"
              endIcon={<CheckCircleOutlineIcon />}
              sx={{
                background: theme.palette.playedEventBtnColor.main,
                color: theme.palette.playedEventBtnColor.dark,
                fontWeight: 'bold',
                width: '49%',
                height: '50px',
                '&:hover': {
                  backgroundColor: '#D1FAE5',
                  color: '#059669'
                }
              }}
              onClick={() => {
                setPopup({
                  open: true,
                  title: `${t('calendar.confirmationAppointment.playedPopupTitle')}`,
                  content: `${t('calendar.confirmationAppointment.playedPopupContent')}`,
                  variant: 'success',
                  buttonText: `${t('popup.info')}`,
                  onClick: () => {
                    selectedAppointmentByID &&
                      confirmAppointment(selectedAppointmentByID?.data.data.id);
                    resetPopup();
                  }
                });
              }}
            >
              {t('calendar.confirmationAppointment.playedAppointmentButton')}
            </Button>
            <Button
              variant="contained"
              endIcon={<WarningAmberIcon />}
              sx={{
                ml: 1.5,
                background: theme.palette.unPlayedEventBtnColor.main,
                color: theme.palette.unPlayedEventBtnColor.dark,
                fontWeight: 'bold',
                width: '49%',
                height: '50px',
                '&:hover': {
                  backgroundColor: '#FEE2E2',
                  color: '#D50000'
                }
              }}
              onClick={() => {
                setPopup({
                  open: true,
                  title: `${t('calendar.confirmationAppointment.unplayedPopupTitle')}`,
                  content: `${t('calendar.confirmationAppointment.unplayedPopupContent')}`,
                  variant: 'error',
                  buttonText: `${t('popup.delete')}`,
                  onClick: () => {
                    selectedAppointmentByID &&
                      declineAppointment(selectedAppointmentByID?.data.data.id);
                    resetPopup();
                  }
                });
              }}
            >
              {t('calendar.confirmationAppointment.unplayedAppointmentButton')}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <Box component={'form'} onSubmit={handleRegularAppointmentSubmit(onRegularAppointmentSubmit)}>
        <Grid container spacing={2}>
          {/* Tereni i Sport */}
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={'bold'}>
              {t('fields') + ' ' + t('and') + ' ' + t('sport')}
            </Typography>
            <Typography> {t('calendar.regularAppointment.field_sport_subtitle')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="court_sport_id"
              rules={{ required: t('errorMessage').toString() }}
              control={controlRegularAppointment}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl className="row justify-between wrap fullWidth">
                  <InputLabel id="fields-sports-selectlabel" error={invalid}>
                    {`${t('field')} / ${t('sport')}  *`}
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="fields-sports-selectlabel"
                    id="fields-sports-select"
                    label={`${t('field')} / ${t('sport')}  *`}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                    endAdornment={!courtSport ? <CircularProgress /> : null}
                    disabled={!!appointmentID && !editAppointment}
                  >
                    {courtItems && courtItems.length > 0
                      ? courtItems.map(coSp => (
                          <MenuItem key={coSp.id} value={coSp.id}>
                            {coSp.name}
                          </MenuItem>
                        ))
                      : sportItems && sportItems.length > 0
                      ? sportItems.map(coSp => (
                          <MenuItem key={coSp.id} value={coSp.id}>
                            {coSp.name}
                          </MenuItem>
                        ))
                      : courtSport.map(coSp => (
                          <MenuItem key={coSp.id} value={coSp.id}>
                            {coSp.name}
                          </MenuItem>
                        ))}
                  </Select>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={'bold'}>
              {t('calendar.regularAppointment.title')}
            </Typography>
            <Typography>{t('calendar.regularAppointment.subtitle')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="regularAppointment_date"
              control={controlRegularAppointment}
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={lng === 'en' ? 'en' : 'sr'}
                >
                  <DatePicker
                    label={t('date') + ' *'}
                    format="DD.MM.YYYY"
                    sx={{ width: '100%' }}
                    {...field}
                    disabled={!!appointmentID && !editAppointment}
                    minDate={
                      appointmentID &&
                      getRegularAppointmentValues('regularAppointment_date')?.isBefore(dayjs())
                        ? null
                        : dayjs()
                    }
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </LocalizationProvider>
              )}
            />
          </Grid>
          {/* time start */}
          <Grid item xs={6}>
            <Controller
              name="datetime_start"
              control={controlRegularAppointment}
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { invalid, error } }) => (
                <>
                  <CustomTimePicker
                    field={field}
                    id="regularAppointment.time_start"
                    labelId="regularAppointment.time_start-label"
                    label={t('calendar.regularAppointment.appointment_start') + '*'}
                    invalid={invalid}
                    disabled={
                      (!!appointmentID && !editAppointment) ||
                      !getRegularAppointmentValues('court_sport_id') ||
                      arrayOfAvailableTimes.length < 1
                    }
                    availableTimes={arrayOfAvailableTimes}
                    helperText={
                      error
                        ? error.message
                        : arrayOfAvailableTimes.length < 1
                        ? `${t('calendar.regularAppointment.unavailableStartTimes')}`
                        : undefined
                    }
                    className="fullWidth"
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="minutes"
              control={controlRegularAppointment}
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl className="row justify-between wrap fullWidth">
                  <InputLabel id="duration-time-selectlabel" error={invalid}>
                    {t('calendar.regularAppointment.appointment_duration_time') + ' *'}
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="duration-time-selectlabel"
                    id="duration-time-select"
                    label={t('calendar.regularAppointment.appointment_duration_time') + ' *'}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                    disabled={
                      !getRegularAppointmentValues('datetime_start') ||
                      (!!appointmentID && !editAppointment) ||
                      dayjs().isAfter(
                        dayjs(
                          `${getRegularAppointmentValues('regularAppointment_date')?.format(
                            'YYYY-MM-DD'
                          )} ${getRegularAppointmentValues('datetime_start')}`
                        )
                      ) ||
                      !getRegularAppointmentValues('court_sport_id')
                    }
                  >
                    {menuItemsDurationTime}
                  </Select>
                  {error ? (
                    <FormHelperText error>{error.message}</FormHelperText>
                  ) : !appointmentID &&
                    getRegularAppointmentValues('datetime_start') &&
                    dayjs().isAfter(
                      dayjs(
                        `${getRegularAppointmentValues('regularAppointment_date')?.format(
                          'YYYY-MM-DD'
                        )} ${getRegularAppointmentValues('datetime_start')}`
                      )
                    ) ? (
                    <FormHelperText error>{t('calendar.old_date_error_message')}</FormHelperText>
                  ) : null}
                </FormControl>
              )}
            />
          </Grid>
          {/* Korisnici */}
          <Grid item xs={12} flexDirection={'column'}>
            <Typography variant="h6" fontWeight={'bold'}>
              {t('users')}
            </Typography>
            <Typography>{t('calendar.regularAppointment.user_subtitile')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="user_id"
              rules={{ required: t('errorMessage').toString() }}
              control={controlRegularAppointment}
              render={({ fieldState: { invalid, error } }) => (
                <FormControl className="row justify-between wrap fullWidth" error={invalid}>
                  <InputLabel
                    id="users-name-selectlabel"
                    htmlFor="outlined-search-users-appointment"
                  >
                    {t('users') + ' *'}
                  </InputLabel>
                  <OutlinedInput
                    onClick={() => setOpenUsersMenu(true)}
                    value={userInput.name}
                    onChange={handleSearch}
                    aria-describedby="base-name-helper-text"
                    inputProps={{
                      'aria-label': 'weight'
                    }}
                    label={t('users') + ' *'}
                    id="outlined-search-users-appointment"
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="search users" edge="end">
                          <SearchIcon color="disabled" />
                        </IconButton>
                      </InputAdornment>
                    }
                    disabled={
                      (!!appointmentID && !editAppointment) || currentCMS?.role === RoleEnum.COACH
                    }
                  />
                  <Box
                    ref={refUsersMenu}
                    sx={{ position: 'absolute', width: '100%', display: 'flex', zIndex: 999 }}
                    mt={7.5}
                  >
                    {openUsersMenu ? (
                      <Paper
                        elevation={3}
                        sx={{
                          height: 150,
                          width: '100%',
                          overflowY: flattenedData.length > 3 ? 'scroll' : 'hidden'
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setAppointmentForm({
                              formType: 'regularAppointment',
                              formObject: {
                                court_sport_id: getRegularAppointmentValues('court_sport_id'),
                                regularAppointment_date:
                                  getRegularAppointmentValues('regularAppointment_date'),
                                datetime_start: getRegularAppointmentValues('datetime_start'),
                                minutes: getRegularAppointmentValues('minutes'),
                                description: getRegularAppointmentValues('description')
                              }
                            });
                            navigate(`/users/${sportCenterID}/create-user`);
                          }}
                        >
                          <PlusCircle />
                          <Typography
                            ml={'2%'}
                            color={'primary.main'}
                            fontSize={'1rem'}
                            fontWeight={600}
                            borderBottom={'1px solid #6366F1'}
                          >
                            {t('superAdminUsers.addNewUser')}
                          </Typography>
                        </MenuItem>
                        {isLoading ? <CircularProgress /> : menuItemsUsers}
                        {isFetching && (
                          <MenuItem>{t('superAdminUsers.loadingUsers') + '...'}</MenuItem>
                        )}
                      </Paper>
                    ) : null}
                  </Box>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          {/* Dodaci */}
          <Grid item xs={12} flexDirection={'column'}>
            <Typography variant="h6" fontWeight={'bold'}>
              {t('sidebar.appendices')}
            </Typography>
            <Typography>{t('calendar.regularAppointment.appendices_subtitle')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="regularAppointment_appendices"
              control={controlRegularAppointment}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl className="row justify-between wrap fullWidth">
                  <InputLabel id="appendices-name-selectlabel" error={invalid}>
                    {t('sidebar.appendices') + ' *'}
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="appendices-name-selectlabel"
                    id="appendices-name-select"
                    label={t('sidebar.appendices')}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                    disabled={true}
                  >
                    <MenuItem>
                      <em>NONE</em>
                    </MenuItem>
                  </Select>
                  <FormHelperText>{t('coming_soon')}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {/* Komentar */}
          <Grid item xs={12} flexDirection={'column'}>
            <Typography variant="h6" fontWeight={'bold'}>
              {t('comment')}
            </Typography>
            <Typography> {t('calendar.regularAppointment.comment_subtitle')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={controlRegularAppointment}
              render={({ field, fieldState: { error, invalid } }) => (
                <FormControl className="row justify-between wrap fullWidth">
                  <TextField
                    {...field}
                    label={t('description')}
                    variant="outlined"
                    placeholder={t('description_placeholder').toString()}
                    sx={{ width: '100%' }}
                    error={invalid}
                    disabled={!!appointmentID && !editAppointment}
                  />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          {/* this message */}
          <Grid item xs={12}>
            {selectedAppointmentByID?.data.data.reserved_from !==
            AppointmentReservedFromEnum.client ? (
              <Typography color="primary">
                {t('calendar.regularAppointment.info_message')}
              </Typography>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            gap={2}
          >
            <Button sx={{ color: 'gray' }} onClick={onClose}>
              {selectedAppointmentByID && dayjs().isAfter(appointmentDateTime)
                ? t('back')
                : t('cancel')}
            </Button>
            {selectedAppointmentByID?.data.data.reserved_from ===
              AppointmentReservedFromEnum.client ||
            (selectedAppointmentByID && dayjs().isAfter(appointmentDateTime)) ? null : (
              <Button
                type="submit"
                variant="contained"
                disabled={!!appointmentID && !editAppointment}
              >
                {t('save')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddRegularAppointment;
