// serbian
import srGlobalsTranslation from './globals/globals.json';
import srSidebarTranslation from './sidebar/sidebar.json';
import srLoginTranslation from './login/login.json';
import srTableTranslation from './table/table.json';
import srSportCenterTranslation from './sportCenter/sportCenter.json';
import srUsersTranslation from './users/users.json';
import srEmployeesTranslation from './employees/employees.json';
import srSettingsTranslation from './settings/settings.json';
import srCalendarTranslation from './calendar/calendar.json';
import srCoachesTranslation from './coaches/coaches.json';
import srNonWorkingDaysTranslation from './nonWorkingDays/nonWorkingDays.json';
import srAnalyticsTranslation from './analytics/analytics.json';

import srFinancesTranslation from './finances/finances.json';

export const translationSerbian = {
  ...srGlobalsTranslation,
  ...srSidebarTranslation,
  ...srLoginTranslation,
  ...srTableTranslation,
  ...srSportCenterTranslation,
  ...srUsersTranslation,
  ...srEmployeesTranslation,
  ...srSettingsTranslation,
  ...srCalendarTranslation,
  ...srCoachesTranslation,
  ...srNonWorkingDaysTranslation,
  ...srAnalyticsTranslation,
  ...srFinancesTranslation
};
