//react-query
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';
//interfaces
import {
  WholeUsersResponse,
  User,
  UserResponse,
  UserErrorResponse,
  UserErrorFullResponse,
  AdminUserResponseType
} from '@interfaces/user/user';
//service
import {
  authUser,
  singleUser,
  getSportCenterUsers,
  sportCenterUsersInfinity,
  users,
  usersInfinity,
  singleAdminUser,
  sportCenterUsersMembershipsInfinity
} from '@services/user/user';
import { isImpersonating } from '@services/impersonation/impersonation';

export const useLoggedUser = (
  onSuccess: (data: User) => void,
  onError: (err: UserErrorResponse) => void
): UseQueryResult<UserResponse, UserErrorFullResponse> =>
  useQuery(['authUser'], authUser, {
    select: res => res.data,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response),
    retry: false
  });

export const useUsers = (
  pageIndex: number,
  pageSize: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string,
  onError: (err: Error) => void
): UseQueryResult<WholeUsersResponse, Error> =>
  useQuery(
    ['users', pageIndex, pageSize, search, gender, order_by, order_direction],
    () => users(pageIndex, pageSize, search, gender, order_by, order_direction),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterInfinityUsers = (
  sportCenter: number | undefined,
  pageSize: number,
  search: string,
  onError: (err: Error) => void
): UseInfiniteQueryResult<WholeUsersResponse, Error> =>
  useInfiniteQuery(
    ['sportCenterInfinityUsers', sportCenter, pageSize, search],
    a => sportCenterUsersInfinity(sportCenter, a.pageParam, pageSize, search, '', '', ''),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.last_page !== lastPage.data.meta.current_page
          ? lastPage.data.meta.current_page + 1
          : undefined,
      onError: err => onError(err)
    }
  );

export const useSportCenterInfinityMembershipsUsers = (
  sportCenter: number | undefined,
  pageSize: number,
  search: string,
  orderBy: string,
  orderDirection: string,
  onError: (err: Error) => void
): UseInfiniteQueryResult<WholeUsersResponse, Error> =>
  useInfiniteQuery(
    ['sportCenterInfinityUsers', sportCenter, pageSize, search, orderBy, orderDirection],
    a =>
      sportCenterUsersMembershipsInfinity(
        sportCenter,
        a.pageParam,
        pageSize,
        search,
        '',
        orderBy,
        orderDirection
      ),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.last_page !== lastPage.data.meta.current_page
          ? lastPage.data.meta.current_page + 1
          : undefined,
      onError: err => onError(err)
    }
  );

export const useInfinityUsers = (
  pageSize: number,
  search: string,
  orderBy: string,
  orderDirection: string,
  onError: (err: Error) => void
): UseInfiniteQueryResult<WholeUsersResponse, Error> =>
  useInfiniteQuery(
    ['infinityUsers', pageSize, search, orderBy, orderDirection],
    a => usersInfinity(a.pageParam, pageSize, search, '', orderBy, orderDirection),
    {
      getNextPageParam: lastPage =>
        lastPage.data.meta.last_page !== lastPage.data.meta.current_page
          ? lastPage.data.meta.current_page + 1
          : undefined,
      onError: err => onError(err)
    }
  );

export const useSingleUser = (
  id: number | null,
  onError: (err: Error) => void
): UseQueryResult<{ data: UserResponse }, Error> =>
  useQuery(
    ['singleUser', id],
    () => {
      if (id) return singleUser(id);
      return null;
    },
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterUsers = (
  sportCenterId: number,
  pageIndex?: number,
  pageSize?: number,
  search?: string,
  gender?: string,
  order_by?: string,
  order_direction?: string,
  onError?: (err: Error) => void
): UseQueryResult<WholeUsersResponse, Error> =>
  useQuery(
    [
      'sportCenterUsers',
      sportCenterId,
      pageIndex,
      pageSize,
      search,
      gender,
      order_by,
      order_direction
    ],
    () =>
      getSportCenterUsers(
        sportCenterId,
        pageIndex,
        pageSize,
        search,
        gender,
        order_by,
        order_direction
      ),
    {
      onError: err => onError && onError(err)
    }
  );

export const useSingleAdminUser = (
  id: number | null,
  sportCenterId: number | null,
  onError: (err: Error) => void
): UseQueryResult<{ data: AdminUserResponseType }, Error> =>
  useQuery(
    ['singleUser', id, sportCenterId],
    () => {
      if (id && sportCenterId) return singleAdminUser(id, sportCenterId);
      return null;
    },
    {
      onError: err => onError(err)
    }
  );

export const useIsImpersonating = (
  onError: (err: Error) => void
): UseQueryResult<{ data: { is_impersonating: boolean } }, Error> =>
  useQuery(['isImpersonating'], () => isImpersonating(), {
    onError: err => onError(err)
  });
