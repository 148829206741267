//hooks
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//mutations
import {
  useCreateSport,
  useDeleteSportImage,
  useUpdateSport,
  useUploadSportImage
} from '@api/mutations/sport/sport';
//queries
import { useSingleSport } from '@api/queries/sport/sport';
import {
  useDeleteCustomField,
  useUpdateSportCustomField
} from '@api/mutations/sportCustomFields/sportCustomFields';
//types
import { ImageType, UploadImageResponse } from '@interfaces/Images/images';
import { CreateSportRequest, Sport, SportCustomFields } from '@interfaces/sport/sport';
import { SportCustomFieldsTypeEnum } from '@enum/sportEnum';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
//helpers
import { getTranslateArray, getTranslateName, onUploadProgress } from '@helpers/utility';
import { formHelper } from '@helpers/formDataHelper';
//recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { sportFormAtom } from '@atoms/sportAtom';

export function useCreateSportHook() {
  const setPopup = useSetRecoilState(popupAtom);
  const [values, setValues] = useState<CreateSportRequest>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const { t } = useTranslation();
  const { sportId } = useParams();
  const lng = localStorage.getItem('lng');
  const navigate = useNavigate();
  const [sportForm, setSportForm] = useRecoilState(sportFormAtom);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<CreateSportRequest>({
    defaultValues: {
      name_translates: {
        en: '',
        sr: ''
      },
      benefits: {
        type: SportCustomFieldsTypeEnum.checklist,
        label_translates: { en: 'benefits', sr: 'benefiti' },
        options_translates: { en: [''], sr: [''] }
      },
      custom_fields: [
        {
          type: '',
          label_translates: {
            en: '',
            sr: ''
          },
          options_translates: {
            en: [],
            sr: []
          }
        }
      ]
    },
    values
  });

  const { refetch } = useSingleSport(
    sportId,
    (sport: Sport | undefined) => {
      if (sport) {
        let custom_fields_tmp: SportCustomFields[] = [];

        let benefits: SportCustomFields = {
          type: SportCustomFieldsTypeEnum.checklist,
          label: 'benefits',
          label_translates: { en: 'benefits', sr: 'benefiti' },
          options_translates: { en: [], sr: [] },
          options: []
        };

        sport.custom_fields.map(f => {
          if (f.type === 'checklist') {
            benefits = f;
          } else {
            custom_fields_tmp.push(f);
          }
        });

        if (custom_fields_tmp.length === 0) {
          custom_fields_tmp.push({
            type: '',
            label: '',
            label_translates: { en: '', sr: '' },
            options: [],
            options_translates: { en: [], sr: [] }
          });
        }

        sport.image && setImages([sport.image]);

        setValues({
          name: sport.name,
          name_translates: sport.name_translates,
          benefits: benefits,
          custom_fields: custom_fields_tmp,
          image: undefined
        });
      }
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: createSport } = useCreateSport(
    data => {
      reset();
      setImages([]);
      if (sportForm?.fromSportForm && sportForm?.sportCenterID) {
        navigate(`/objects/create-object/${sportForm.sportCenterID}/court`);
      } else {
        navigate('/settings/sports');
      }
      setSportForm({ sportID: data.id.toString() });
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: editSport } = useUpdateSport(
    data => {
      navigate(-1);
      refetch();
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: deleteCustomField } = useDeleteCustomField(
    mess => {
      refetch();
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: updateCustomField } = useUpdateSportCustomField(
    mess => {
      refetch();
      reset();
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: uploadImage } = useUploadSportImage(
    (data: UploadImageResponse) => {
      setImages([data.data]);
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: deleteImage } = useDeleteSportImage(
    () => {
      refetch();
      setImages([]);
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'custom_fields',
    keyName: 'arrayId'
  });

  const onSubmit = (data: CreateSportRequest) => {
    if ((!lng || lng === 'sr') && data.benefits?.options_translates?.sr) {
      const invalidIndex = data.benefits.options_translates.sr.indexOf('');
      if (invalidIndex !== -1) {
        setError(`benefits.options_translates.sr.${invalidIndex}`, {
          type: 'custom'
        });
        return;
      }
    }
    if (lng === 'en' && data.benefits?.options_translates?.en) {
      const invalidIndex = data.benefits.options_translates.en.indexOf('');
      if (invalidIndex !== -1) {
        setError(`benefits.options_translates.en.${invalidIndex}`, {
          type: 'custom'
        });
        return;
      }
    }

    if (sportId) {
      if (data.benefits) data.custom_fields.push(data.benefits);
      editSport({
        id: +sportId,
        name: getTranslateName(lng, data.name_translates, data.name),
        name_translates: data.name_translates,
        custom_fields: data.custom_fields
      });
    } else {
      if (data.benefits) data.custom_fields.push(data.benefits);
      data.name = getTranslateName(lng, data.name_translates, data.name);
      for (let i = 0; i < data.custom_fields.length; i++) {
        data.custom_fields[i].options = getTranslateArray(
          lng,
          data.custom_fields[i].options_translates,
          data.custom_fields[i].options
        );
        data.custom_fields[i].label = getTranslateName(
          lng,
          data.custom_fields[i].label_translates,
          data.custom_fields[i].label
        );
      }
      formHelper.getBody(data);
      createSport(formHelper.getBody(data));
    }
  };

  const inputTypes: SportCustomFieldsTypeEnum[] = [
    SportCustomFieldsTypeEnum.number,
    SportCustomFieldsTypeEnum.text,
    SportCustomFieldsTypeEnum.select
  ];

  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  const onDropCallback = (image: File) => {
    if (images.length > 0) {
      return;
    }

    if (sportId) {
      const data = new FormData();
      data.append('image', image);
      uploadImage({
        id: sportId,
        data: data,
        uploadPercentOptions: uploadPercentOptions
      });
    } else {
      setValue('image', image);
      setImages([
        {
          id: Math.floor(Math.random() * 100),
          src: URL.createObjectURL(image)
        }
      ]);
    }
  };

  const handleDelete = (imageID: number) => {
    if (sportId) {
      deleteImage({ objectId: +sportId, imageID: imageID });
    } else {
      setImages(images.filter(i => i.id !== imageID));
    }
  };

  const handleDeleteCustomField = (index: number, id?: number) => {
    if (sportId && id) {
      deleteCustomField({ sportId: +sportId, customFieldId: id });
    } else if (fields.length > 1) remove(index);
  };

  const handelDeleteBenefit = (index: number, benefits: string) => {
    if (sportId && values && values.benefits && values.benefits.id) {
      const new_options_serbian = values.benefits.options_translates?.sr?.filter(
        (e, ind) => ind !== index
      );
      if (values.benefits.options_translates?.sr)
        values.benefits.options_translates.sr = new_options_serbian ? new_options_serbian : [];

      const new_options_english = values.benefits.options_translates?.en?.filter(
        (e, ind) => ind !== index
      );
      if (values.benefits.options_translates?.en)
        values.benefits.options_translates.en = new_options_english ? new_options_english : [];

      updateCustomField({ sportId: +sportId, ...values.benefits });
    } else {
      const new_value_serbian = getValues('benefits.options_translates.sr')?.filter(
        (e, ind) => ind !== index
      );
      setValue('benefits.options_translates.sr', new_value_serbian ? new_value_serbian : []);
      const new_value_english = getValues('benefits.options_translates.en')?.filter(
        (e, ind) => ind !== index
      );
      setValue('benefits.options_translates.en', new_value_english ? new_value_english : []);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    fields,
    append,
    remove,
    setValue,
    getValues,
    setError,
    watch,
    control,
    errors,
    inputTypes,
    reset,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    title: sportId ? t('sportPage.editTitle') : t('sportPage.createTitle'),
    subtitle: sportId ? t('sportPage.editSubtitle') : t('sportPage.createSubtitle'),
    handleDeleteCustomField,
    handelDeleteBenefit,
    clearErrors,
    lng
  };
}
