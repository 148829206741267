//icons
import { RoundedMinusIcon, RoundedPlusIcon } from '@components/icons/icons';
//components
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import UploadImage from '@containers/ImageUpload/ImageUpload';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
//react use form
import { Controller } from 'react-hook-form';
//hooks
import { useTranslation } from 'react-i18next';
import { useCreateSportHook } from '@hooks/useCreateSportHook/useCreateSportHook';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '@hooks/windowSize/useWindowSize';

const CreateSportPage = () => {
  const {
    handleSubmit,
    onSubmit,
    fields,
    append,
    setValue,
    getValues,
    control,
    watch,
    errors,
    inputTypes,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    title,
    subtitle,
    handleDeleteCustomField,
    handelDeleteBenefit,
    clearErrors,
    lng
  } = useCreateSportHook();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { width } = useWindowSize();

  return (
    <Box p={3}>
      <CardWrapper title={title} subtitle={subtitle} isForm={true}>
        <Grid container spacing={2}>
          <Grid container justifyContent={'flex-end'} pl={9}>
            <Grid item xs={12}>
              <Controller
                rules={{ required: !lng || lng === 'sr' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={
                      !lng || lng === 'sr'
                        ? t('sportPage.sportName') + ' ' + t('sportPage.in_serbian') + ' *'
                        : t('sportPage.sportName') +
                          ' ' +
                          t('sportPage.in_serbian') +
                          ' * ' +
                          t('sportPage.optional')
                    }
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                name={`name_translates.sr`}
                control={control}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Controller
                rules={{ required: lng === 'en' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={
                      lng === 'en'
                        ? t('sportPage.sportName') + ' ' + t('sportPage.in_english') + ' *'
                        : t('sportPage.sportName') +
                          ' ' +
                          t('sportPage.in_english') +
                          ' * ' +
                          t('sportPage.optional')
                    }
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                name={`name_translates.en`}
                control={control}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {fields.map((item, index) => (
              <Grid item xs={12} key={index} display={'flex'} gap={2} mb={2}>
                <IconButton
                  onClick={() => {
                    handleDeleteCustomField(index, item.id);
                    // if (fields.length > 1) remove(index);
                  }}
                >
                  <RoundedMinusIcon />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          label={
                            !lng || lng === 'sr'
                              ? t('characteristic') + ' ' + t('in_serbian') + ' *'
                              : t('characteristic') + ' ' + t('in_serbian') + ' * ' + t('optional')
                          }
                          fullWidth
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                      name={`custom_fields.${index}.label_translates.sr`}
                      control={control}
                      rules={{ required: !lng || lng === 'sr' ? true : false }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          label={
                            lng === 'en'
                              ? t('characteristic') + ' ' + t('in_english') + ' *'
                              : t('characteristic') + ' ' + t('in_english') + ' * ' + t('optional')
                          }
                          fullWidth
                          error={invalid}
                          helperText={error?.message}
                        />
                      )}
                      name={`custom_fields.${index}.label_translates.en`}
                      control={control}
                      rules={{ required: lng === 'en' ? true : false }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name={`custom_fields.${index}.type`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel error={invalid}>{t('sportPage.fieldType')}</InputLabel>
                          <Select
                            {...field}
                            label={t('sportPage.fieldType')}
                            variant="outlined"
                            sx={{ width: '100%' }}
                            error={invalid}
                          >
                            {inputTypes.map(type => (
                              <MenuItem key={type} value={type}>
                                {t(`${type}`)}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText error>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <IconButton onClick={() => append({ type: '', label: '', options: [] })}>
              <RoundedPlusIcon />
            </IconButton>
          </Grid>

          <Grid container ml={2} justifyContent={'space-between'}>
            <Grid item xs={12} sm={6.2}>
              {watch('benefits')?.options_translates?.sr?.map((el, i) => (
                <Grid item xs={12} key={i} mb={2}>
                  <FormControl fullWidth>
                    <Box display={'flex'} gap={2}>
                      <IconButton
                        onClick={() => {
                          if ((i === 0 && el) || i !== 0) handelDeleteBenefit(i, el);
                        }}
                      >
                        <RoundedMinusIcon />
                      </IconButton>
                      <TextField
                        sx={{ width: '100%' }}
                        label={
                          !lng || lng === 'sr'
                            ? t('benefits') + ' ' + t('in_serbian') + ' *'
                            : t('benefits') + ' ' + t('in_serbian') + ' * ' + t('optional')
                        }
                        value={watch(`benefits.options_translates.sr.${i}`)}
                        onChange={ev => {
                          if (!!errors.benefits?.options_translates?.sr?.[i]) clearErrors();
                          setValue(`benefits.options_translates.sr.${i}`, ev.target.value);
                        }}
                        error={
                          !!errors.benefits?.options_translates?.sr?.[i] ||
                          ((!lng || lng === 'sr') && !!errors.benefits?.options?.[i])
                        }
                        helperText={
                          !!errors.benefits?.options_translates?.sr?.[i]?.message ||
                          ((!lng || lng === 'sr') && !!errors.benefits?.options?.[i]?.message)
                        }
                      />
                    </Box>
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} sm={5.6}>
              {watch('benefits')?.options_translates?.en?.map((el, i) => (
                <Grid item xs={12} key={i} mb={2} ml={width < 600 ? 7 : 0}>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        lng === 'en'
                          ? t('benefits') + ' ' + t('in_english') + ' *'
                          : t('benefits') + ' ' + t('in_english') + ' * ' + t('optional')
                      }
                      value={watch(`benefits.options_translates.en.${i}`)}
                      onChange={ev => {
                        if (!!errors.benefits?.options_translates?.en?.[i]) clearErrors();
                        setValue(`benefits.options_translates.en.${i}`, ev.target.value);
                      }}
                      error={
                        !!errors.benefits?.options_translates?.en?.[i] ||
                        (lng === 'en' && !!errors.benefits?.options?.[i])
                      }
                      helperText={
                        !!errors.benefits?.options_translates?.en?.[i]?.message ||
                        (lng === 'en' && !!errors.benefits?.options?.[i]?.message)
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                let new_value_serbian = getValues('benefits.options_translates.sr');
                let new_value_english = getValues('benefits.options_translates.en');

                if (new_value_serbian) new_value_serbian.push('');
                else new_value_serbian = [''];
                if (new_value_english) new_value_english.push('');
                else new_value_english = [''];

                setValue('benefits.options_translates.sr', new_value_serbian);
                setValue('benefits.options_translates.en', new_value_english);
              }}
            >
              <RoundedPlusIcon />
            </IconButton>
          </Grid>
        </Grid>

        <UploadImage
          title={t('sportPage.sportIcon')}
          images={images}
          onDropCallback={onDropCallback}
          handleDelete={handleDelete}
          uploadPercent={uploadPercent}
        />
      </CardWrapper>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
        <Button onClick={() => navigate(-1)}>{t('cancel')}</Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateSportPage;
